<div class="filter_flx flx_entity_col  pt_users d-flex align-items-center w-100">
  <div class="flex-column filter_title me-auto">
    <div class="text-capitalize mb-0 list_heading">
      <span [innerHtml]="staticText?.user_management?.user?.list?.list_card_title"></span>
      <span class="total_records mx-2 " *ngIf="entityList">({{totalRecords}})</span>
    </div>
    <span class="users_clr">{{staticText?.user_management?.entity?.list?.breadcrumbs}}</span>
  </div>
  <div class="custom_filter_flx w_full_users d-flex justify-content-end align-items-center">


    <div class="d-flex w_full_filter">

      <custom-table-filter class="w_full_filter" [customTableFilterLabelClass]="''"
        [customTableFilterContainerClass]="'roles_width'" [customTableFilterSource]="filterSource"
        (customTableFilterValueEmitter)="filterParams($event)">
      </custom-table-filter>
    </div>

    <custom-table-actions class="pos_export" [tableExport]="tableExport"
      [addBtnTitle]="staticText?.user_management?.entity?.list?.new_user" [isDefaultClass]="false"
      [isButtonAction]="true" [permission]="permission.ADD_ENTITY" (add)="addEntity()" [downloadAPI]="listAPI"
      [filterParams]="filterParamsExport" [tableFields]="tableFields">
    </custom-table-actions>


  </div>
</div>


<mat-card-content>
  <div class="d-flex flex-column p-4">
    <!-- <custom-table-filter [customTableFilterSource]="filterSource"
            (customTableFilterValueEmitter)="filterParams($event)">
            </custom-table-filter> -->
    <div class="d-flex">
      @for (item of _common.userMangementTab; track $index) {
      <div class="user-mgnt-tab-btn cursor-pointer" [ngClass]="{'active' : item.active}" (click)="tabClick(item)">
        <span class="users_grp">{{item.title}}</span>
      </div>
      }
    </div>
    <custom-table tableId="cust-tbl-entitylist" [localColumnSearch]="true" [columnSearchOptionsList]="[]"
      [internalColumnSearch]="false" (columnSearch)="columnSearch($event)" [tableCustomActionSelectAll]="tableHeader"
      [columnDefs]="tableFields" [tablePaginationCustom]="true" [stickyColumnSize]="2" [stickyClass] = "true" [tableRowData]="entityList"
      [tableTotalRecords]="totalRecords" (pageChange)="pageChange($event)"
      [tableCustomActionPermissions]="actionPermissions" (actionParam)="editEntity($event)"
      (sortChange)="sortColumn($event)">
    </custom-table>
  </div>
</mat-card-content>