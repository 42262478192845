import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/core/services/common/common.service';
import { HttpService } from 'src/app/core/services/http/http.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { RouterService } from 'src/app/core/services/router/router.service';
import { SnackbarService } from 'src/app/core/services/snackBar/snackbar.service';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import {
  ApiMethod,
  Endpoints,
  FORMAT,
  PagePermssions,
} from 'src/app/core/services/utils/constants';
import { EntityAddComponent } from '../entity-add/entity-add.component';
import { CustomTableComponent } from 'src/app/shared/components/custom-table/custom-table.component';
import { LodashService } from 'src/app/core/services/lodash/lodash.service';
import { CustomTableService } from 'src/app/shared/services/custom-table/custom-table.service';
import { ActivatedRoute } from '@angular/router';
import { BreadCrumbService } from 'src/app/shared/services/bread-crumb/bread-crumb.service';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { USER_MANAGEMENT } from '../../users/user-table-config/table-schema-model';

@Component({
  selector: 'app-entity-list',
  templateUrl: './entity-list.component.html',
  styleUrls: ['./entity-list.component.scss'],
  providers: [CustomTableService],
})
export class EntityListComponent implements OnInit, OnChanges {
  @ViewChild(CustomTableComponent) cTable!: CustomTableComponent;
  @Input() searchtext: string = '';

  entitySearchForm: FormGroup | undefined;
  searchForm: FormGroup | any;
  totalRecords = 0;
  staticText: any = TranslationService.staticTextData;
  entityList: any[] = [];
  startRow: number =
    this.staticText?.common?.custom_table_properties
      ?.custom_table_pagination_block?.custom_table_page_size?.startRow;
  endRow: number =
    this.staticText?.common?.custom_table_properties
      ?.custom_table_pagination_block?.custom_table_page_size?.endRow;
  columnDefs: any = USER_MANAGEMENT?.entity?.table_fields;
  filterParamValues: any;
  tableFields: any;
  tableExport: any;
  permission: any = PagePermssions;
  actionPermissions = {
    view: [this.permission.VIEW_ENTITY],
    edit: [this.permission.CHANGE_ENTITY],
    delete: [this.permission.DELETE_ENTITY],
  };
  listAPI: any = `${Endpoints.GET_ENTITY_LIST}`;
  filterParamsExport: any = '';
  filterStatusData: any = [
    {
      id: 1,
      name: 'Active',
      key: 'True',
    },
    {
      id: 2,
      name: 'Inactive',
      key: 'False',
    },
  ];
  tableHeader: any = {
    multi: false,
    model: false,
    multiSelect: false,
  };
  filterSource: any = USER_MANAGEMENT?.entity?.table_top_filter;
  filterKeys: any = [
    { key: 'is_active', source: [], sourceKey: 'key' },
    { key: 'date_range' },
  ];
  pageMode: any;

  constructor(
    private _http: HttpService,
    public _common: CommonService,
    public _routeService: RouterService,
    private _loader: LoaderService,
    private _modal: ModalService,
    private _lodash: LodashService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private _snackBar: SnackbarService,
    private datePipe: DatePipe,
    private activated_route: ActivatedRoute,
    private _tableSerivce: CustomTableService,
    protected breadcrumbService: BreadCrumbService
  ) {
    this.tableFields = USER_MANAGEMENT?.entity?.table_fields;
    this.tableExport = USER_MANAGEMENT?.entity?.table_actions?.export;
  }

  ngOnInit(): void {
    this.getRouteParams();
    this.breadcrumbService.breadCrumbView(false);
    this.updateCustomTableConfig();
    this.initialDependencies();
  }

  ngOnChanges(changes: SimpleChanges | any) {
    // if (!changes.searchtext.firstChange)
    //   this.searchKeyword(this.searchtext);
  }

  getRouteParams() {
    const routeInfo: any = this.activated_route?.data;
    this.pageMode = routeInfo?.value?.mode;
    this._common.userMangementTab?.forEach((element: any) => {
      element.active = false;
    });
    this._common.userMangementTab.find(
      (x: any) => x.mode == this.pageMode
    ).active = true;
  }

  tabClick(menu: any) {
    this._common.navigatePages(menu.route);
  }

  getSearchFormVal() {
    return (this.searchForm && this.searchForm.get('keyword').value) || '';
  }

  loadEntityList() {
    this.keyWordSearchFilter(this.getSearchFormVal());
  }

  initialDependencies() {
    this._common?.filterSourceConstructor(this.filterSource, this.filterKeys);
    if (!this.searchtext) {
      this.loadEntityList();
    }
    this.getEntityStatusList();
    this.initiateForm();
  }

  initiateForm() {
    this.searchForm = this.formBuilder.group({
      keyword: [''],
    });
  }

  getEntityStatusList() {
    this.filterKeys[0]['source'] = this.filterStatusData;
    this._common?.filterSourceConstructor(this.filterSource, this.filterKeys);
  }

  resetPagination() {
    this._tableSerivce.currentPage = 1;
    this.startRow =
      this.staticText?.common?.custom_table_properties?.custom_table_pagination_block?.custom_table_page_size?.startRow;
    this.endRow =
      this._tableSerivce.pageSize ||
      this.staticText?.common?.custom_table_properties
        ?.custom_table_pagination_block?.custom_table_page_size?.endRow;
  }

  filterParams(data?: any) {
    this.resetPagination();
    this.filterParamValues = data?.value;
    let param: any = { ...this.filterParamValues, ...this.getColumnSearch() };
    let result: any = this._common.filterParamsConstructor(
      param,
      this.startRow,
      this.endRow
    );
    this.filterExportParams(param);
    this.getEntityList(result);
  }
  sortColumn(data: any) {
    let param: any = {
      ...this.filterParamValues,
      ...data,
      ...this.getColumnSearch(),
    };
    let result: any = this._common.filterParamsConstructor(
      param,
      this.startRow,
      this.endRow
    );
    this.getEntityList(result, true);
  }

  searchKeyword(data: any) {
    this.resetPagination();
    let param: any = {
      ...this.filterParamValues,
      ...{ search: this.searchtext },
    };
    this.getEntityList(
      this._common.filterParamsConstructor(param, this.startRow, this.endRow)
    );
  }

  keyWordSearchFilter(ev: any) {
    this.resetPagination();
    let param: any = {
      ...this.filterParamValues,
      ...{ search: ev },
      ...{ column_search: this.getColumnSearch() },
    };
    let result: any = this._common.filterParamsConstructor(
      param,
      this.startRow,
      this.endRow
    );
    this.getEntityList(result);
  }

  getColumnSearch(): any {
    return this.cTable?.searchObj;
  }

  pageChange(evt: any) {
    this.startRow = evt?.startValue;
    this.endRow = evt?.endValue;

    let param: any = { ...this.filterParamValues, ...this.getColumnSearch() };
    let result: any = this._common.filterParamsConstructor(
      param,
      this.startRow,
      this.endRow
    );
    this.getEntityList(result);
  }

  onChangeActiveDropdown(selectedVal: any, fieldName: any, data: any) {
    let currentEntityList = this._lodash.Clonedata(this.entityList);
    let matchedIndex = this._lodash.findIndexBy(this.entityList, 'id', data.id);
    if (matchedIndex > -1) {
      this.entityList[matchedIndex][fieldName] =
        selectedVal == 'True' || selectedVal == 'true' || selectedVal == true
          ? true
          : false;
      this.updateEntityStatus(this.entityList[matchedIndex], currentEntityList);
    }
    this.resetList(this.entityList);
  }

  resetList(list: any) {
    this.entityList = list;
    this.cTable.refreshTableData(list, false, this.startRow);
  }

  updateEntityStatus(entityObj: any, currentEntityList: any) {
    this._modal
      .openWarningDialog({
        data: {
          paragraph: `<p class="fs-14">Selected entity are mapped to roles and users and will be no longer active.</p>`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this._loader.show();
          let endpoint: any = Endpoints.GET_ENTITY_LIST + entityObj.id + '/';
          let payload = {
            name: entityObj.entity_name,
            display_name: entityObj.display_name,
            is_active: entityObj.is_active,
          };
          this._http.requestCall(endpoint, ApiMethod.PATCH, payload).subscribe(
            (response: any) => {
              this.resetList(this.entityList);
              this._loader.hide();
            },
            (error: any) => {
              this._loader.hide();
              this.resetList(currentEntityList);
            }
          );
        } else {
          this.resetList(currentEntityList);
        }
      });
  }

  updateCustomTableConfig() {
    this.tableFields['is_active'] = {
      ...this.tableFields['is_active'],
      dropdown_config: {
        options: [
          {
            key: 'ACTIVE',
            value: true,
          },
          {
            key: 'INACTIVE',
            value: false,
          },
        ],
        className: 'active-inactive-dropdown',
        events: {
          change: this.onChangeActiveDropdown.bind(this),
          click: () => {},
        },
      },
    };
  }

  onCustomAction(actionData: any) {
    this._routeService.navigatePages(
      '/user-management/view/' + actionData['Data']['id']
    );
  }

  getActiveHtml(data: any) {
    return `<select id="active-${
      data['id']
    }"  class="custom-dropdown"><option value="True" ${
      data['is_active'] ? 'selected' : ''
    }>Active</option><option value="False" ${
      !data['is_active'] ? 'selected' : ''
    }>Inactive</option></select>`;
  }

  getEntityList(params?: any, sorting: boolean = false) {
    this._loader.show();
    let endpoint: any = Endpoints.GET_ENTITY_LIST + params;
    this._http
      .requestCall(endpoint, ApiMethod.GET)
      .subscribe((apiResponse: any) => {
        let response = apiResponse.data || {};
        this._loader.hide();
        this.totalRecords = response?.total_records;
        this.entityList = response?.records?.map((data: any) => ({
          ...data,
          entity_name: data?.display_name || '',
          display_name: data?.display_name || '',
          transaction_number: data?.transaction_number || '',
          created_on:
            this.datePipe?.transform(data.created_on, FORMAT.DATE_TIME) ?? '',
          created_by: data?.created_by || '',
          last_updated_by: data?.last_updated_by || '',
          is_active: data?.is_active,
          description: data?.description || '',
          last_updated_on:
            this.datePipe?.transform(data.last_updated_on, FORMAT.DATE_TIME) ??
            '',
          id: data?.id,
          // "route": [{ mode: 'delete', path: data.id, status: false }]
        }));
        this.cTable.setTableTotalRecordsCnt(this.totalRecords);
        this.cTable.refreshTableData(this.entityList, sorting, this.startRow);

        this._common.mapSerialNumberAndRoutePath(
          this['startRow'] || 0,
          this.entityList,
          [
            {
              status: true,
              route: '/users/view/',
              routeStatus: false,
              tooltip: 'view',
            },
            {
              status: true,
              mode: 'view',
              route: 'edit/',
              tooltip: 'view',
              routeStatus: false,
              icon: 'view_action',
            },
            {
              status: true,
              route: 'edit',
              mode: 'edit',
              routeStatus: false,
              tooltip: 'edit',
            },
          ],
          false
        );

        // this._common.mapSerialNumberAndRoutePath(this.entityList, [{ status: true, route: 'view', routeStatus: false }, { status: true, mode: 'view', route: 'edit', icon: 'view_action',routeStatus:false , tooltip: 'View'} , { status: true, route: 'edit', mode: 'Edit',  routeStatus: true , tooltip: 'edit' }], false)
      });
  }

  public addEntity() {
    this._modal
      .openCommonDialog({
        width: '700px',
        component: EntityAddComponent,
      })
      .afterClosed()
      .subscribe((result: any) => {
        if (result !== false) {
          this.getEntityList(
            this._common.filterParamsConstructor(
              this.filterParamValues,
              this.startRow,
              this.endRow
            )
          );
        }
      });
  }

  editEntity(data: any) {
    this._modal
      .openCommonDialog({
        component: EntityAddComponent,
        data: { data: data },
      })
      .afterClosed()
      .subscribe((result: any) => {
        if (result !== false) {
          this.getEntityList(
            this._common.filterParamsConstructor(
              this.filterParamValues,
              this.startRow,
              this.endRow
            )
          );
        }
      });
  }

  veiwEntity(data: any) {
    this._modal
      .openCommonDialog({
        component: EntityAddComponent,
        data: { data: data },
      })
      .afterClosed()
      .subscribe((result: any) => {
        if (result !== false) {
          this.getEntityList(
            this._common.filterParamsConstructor(
              this.filterParamValues,
              this.startRow,
              this.endRow
            )
          );
        }
      });
  }

  /**
   * @description
   * Table column wise search emitter...............
   * @param ev
   */
  columnSearch(ev: any) {
    this.resetPagination();
    let param: any = { ...this.filterParamValues, ...this.getColumnSearch() };
    let result: any = this._common.filterParamsConstructor(
      param,
      this.startRow,
      this.endRow
    );

    this.filterExportParams(param);
    this.getEntityList(result);
  }
  filterExportParams(param: any) {
    this.filterParamsExport = this._common.filterParamsObject(param);
  }

  //...............Table column wise search emitter
}
