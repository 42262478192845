import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  ViewChild,
  viewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FormValidatorService } from 'src/app/shared/services/form-validator/form-validator.service';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { CommonService } from 'src/app/core/services/common/common.service';
import { HttpService } from 'src/app/core/services/http/http.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { RouterService } from 'src/app/core/services/router/router.service';
import {
  routePath,
  PagePermssions,
  Endpoints,
  ApiMethod,
  SuccessMessage,
  StateManagement,
  PHONE_NUMBER_MAX_LENGTH,
  ErrorMessage,
  FailedMessage,
  colorCodes,
  FORMAT,
} from 'src/app/core/services/utils/constants';
import { LodashService } from 'src/app/core/services/lodash/lodash.service';
import * as moment from 'moment';
import { BreadCrumbService } from 'src/app/shared/services/bread-crumb/bread-crumb.service';
import { DomSanitizer } from '@angular/platform-browser';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import {
  getDistrictList,
  getMzoList,
  getRegionList,
} from 'src/app/state/master/master.selector';
import { AppState } from 'src/app/state/master/master.state';
import { State, Store } from '@ngrx/store';
import { MasterService } from 'src/app/state/master/master.service';
import { SnackbarService } from 'src/app/core/services/snackBar/snackbar.service';
import { UserService } from 'src/app/core/services/user.service';
import { forkJoin } from 'rxjs';
import { LocationFormComponent } from 'src/app/shared/components/location-form/location-form.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { template } from 'lodash';
import { TranslationService } from 'src/app/shared/services/translation.service';

@Component({
  selector: 'app-user-view-edit',
  templateUrl: './user-view-edit.component.html',
  styleUrls: ['./user-view-edit.component.scss'],
})
export class UserViewEditComponent implements OnDestroy {
  @Input() flag: boolean = false;
  usersForm: FormGroup | any;
  location: FormGroup | any;
  phoneNumberMaxLength: number;
  userApiData: any;
  attachedFile: any;
  selectedAttachedFile: any;
  routeEventSubscription: any;
  previewImg: any;
  userApiResponse: any;
  rolesList: any[] = [];
  userId: any;
  routePath: any = routePath;
  permssions: any = PagePermssions;
  staticText: any = TranslationService.staticTextData;
  showPageMode: boolean = false;
  enableEditForSameUser: boolean = false;
  type: any;
  viewUser: any = {
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    date_of_birth: '',
    phone_number: '',
    region: '',
    zone: '',
    district: '',
    user_type: '',
    groups: '',
    profile_photo: '',
    zone_valuer: '',
    country_code: '',
    view_all_entity: true,
  };
  permission: any = PagePermssions;
  regionOptions: any = [];
  zoneAllOptions: any = [];
  districtAllOptions: any = [];
  zoneOptions: any = [];
  districtOptions: any = [];
  departmentOptions: any = [];
  groupsOptions: any = [];
  breadcrumbs: any = [];
  userDetails: any;
  regionListSub$: any;
  mzoListSub$: any;
  districtListSub$: any;
  countries = [{ code: '+250', name: 'Rwanda' }];
  currentDate = new Date();
  userList: boolean = true;
  locationdetails: any = [];
  locationFormConfig: any = {
    multiSelect: true,
    required: false,
  };

  @ViewChild('dynamicLocation') dynamicLocation!: LocationFormComponent;
  minDate: any;
  maxDate: any;
  constructor(
    private store: Store<{ master: AppState }>,
    private state: State<AppState>,
    private route: Router,
    private _http: HttpService,
    protected _common: CommonService,
    private sanitizer: DomSanitizer,
    protected breadcrumbService: BreadCrumbService,
    private formBuilder: FormBuilder,
    private _master: MasterService,
    private _lodash: LodashService,
    private _loader: LoaderService,
    private datePipe: DatePipe,
    private _modal: ModalService,
    public _routeService: RouterService,
    private activated_route: ActivatedRoute,
    private _formValidator: FormValidatorService,
    private router: Router,
    private _storage: StorageService,
    private cdr: ChangeDetectorRef,
    private _snackBar: SnackbarService,
    private _userService: UserService,
    public _createDialogRef: MatDialogRef<UserViewEditComponent>,
    @Inject(MAT_DIALOG_DATA) public majorDialogData: any
  ) {
    this.phoneNumberMaxLength = PHONE_NUMBER_MAX_LENGTH;
    this.initiateMasterReducerCall();
  }

  ngOnInit(): void {
    this.breadcrumbService.breadCrumbView(false);

    this.getUserDetailsId();


    this._userService.locationJson = [];
    this.getLocation(null, 0);
    this.setMinDate();
    this.getRouteParams();
  }

  initiateMasterReducerCall() {
    this.initiateRegionListSub();
    let masterRegionData = this._lodash.getData(
      this.state.getValue(),
      'master.region',
      {}
    );
    if (
      StateManagement.region &&
      !this._lodash.getData(masterRegionData, 'isLoaded', '-1') &&
      !this._lodash.getData(masterRegionData, 'isInProgress', '-1')
    ) {
      //this.getRegion();
    }

    if (!StateManagement.region) {
      //this.getRegion();
    }
  }

  initiateRegionListSub() {
    this.regionListSub$ = this.store
      .select(getRegionList)
      .subscribe((data: any) => {
        if (data.length) {
          this.regionOptions = data || [];
        }
      });
  }

  initiateMzoListSub() {
    this.mzoListSub$ = this.store.select(getMzoList).subscribe((data: any) => {
      if (data.length) {
        this.zoneAllOptions = data || [];
      }
    });
  }

  initiateDistrictListSub() {
    this.districtListSub$ = this.store
      .select(getDistrictList)
      .subscribe((data: any) => {
        if (data.length) {
          this.districtAllOptions = data || [];
        }
      });
  }

  getUserDetailsId() {
    this.userDetails = this._storage.getUserDetails();
  }

  onClickFn() {
    this._routeService.navigatePages(
      '/user-management/users/edit/' + this.userId
    );
  }

  setBreadCrumb() {
    let breadcrumbActionBtn =
      this.type === 'view' &&
        this.userApiResponse &&
        !this.userApiResponse.is_private
        ? {
          label: 'Edit User',
          onClickActionFn: this.onClickFn.bind(this),
          icon: 'arrow_right_dark',
          permission: [this.permssions.CHANGE_CUSTOMUSER],
        }
        : {};
    this.breadcrumbs = [
      {
        label: 'User Management',
        url: 'user-management/users',
      },
    ];
    if (this.type == 'view') {
      this.breadcrumbs.push({
        label: 'User',
        url: 'user-management/users',
      });
    } else if (this.type == 'edit') {
      this.breadcrumbs.push({
        label: 'User',
        url: '',
      });
    } else {
      this.breadcrumbs.push({
        label: 'Add New User',
        url: '',
      });
    }

    this.breadcrumbService.setCustomBreadCrumb(this.breadcrumbs);
    this.breadcrumbService.setCustomBreadCrumbAction(breadcrumbActionBtn);
  }

  /* onSelectedFiles(ev: any) {
     this.selectedAttachedFile = ev[0];
     this.previewImg = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(this.selectedAttachedFile)));
   } */

  initialDependencies() {
    if (this.type == 'edit' || this.type == 'view') {
      this.getUserId();
    }
    this.getDefaultOptions();
    if (this.type == 'edit' || this.type == 'add') {
      this.createForm();
    }
    let apis: any = {
      //getUserDetails : this._userService.getUserList(this.userId),
    };
    if (this.type == 'edit' || this.type == 'view') {
      this._loader.show();
      apis['getUserDetails'] = this._userService.getUserList(this.userId);
    }
    forkJoin(apis)?.subscribe((data: any) => {
      this._loader.hide();
      this.getUserDetails(data);
    });
  }

  getDefaultOptions() {
    if (this.type == 'edit' || this.type == 'add') {
      this.getDepartmentList();
    }
  }

  getRegion() {
    this._common?.updateStoreProgressStatus('region', 'region');
    let endpoint: any = Endpoints.GET_DROPDOWN_REGION_LIST;
    this._http
      .requestCall(endpoint, ApiMethod.GET)
      .subscribe((response: any) => {
        this._common?.updateStoreData('region', 'region', response || []);
        this.regionOptions = response;
      });
  }

  getMZO(idString: string = '-1', updatePatchData: boolean = false) {
    if (idString != '-1' && StateManagement.mzo) {
      this.zoneOptions = this._master.getMasterDataByIds(
        idString,
        'mzo',
        'region'
      );
      return;
    }
    let endpoint: any =
      Endpoints.GET_DROPDOWN_MZO_LIST +
      (idString != '-1' ? '?region=' + idString : '');
    this._common?.updateStoreProgressStatus('mzo', 'mzo');
    this._http
      .requestCall(endpoint, ApiMethod.GET)
      .subscribe((response: any) => {
        if (idString == '-1' && StateManagement.mzo) {
          this.zoneAllOptions = response || [];
          this._common?.updateStoreData('mzo', 'mzo', response.records || []);
        }
        this.zoneOptions = response;
      });
  }

  getDistrict(idString: string = '-1', updatePatchData: boolean = false) {
    if (idString != '-1' && StateManagement.district) {
      this.districtOptions = this._master.getMasterDataByIds(
        idString,
        'district',
        'zone'
      );
      return;
    }
    let endpoint: any =
      Endpoints.GET_DROPDOWN_DISTRICT_LIST +
      (idString != '-1' ? '?zone=' + idString : '');
    this._common?.updateStoreProgressStatus('district', 'district');
    this._http
      .requestCall(endpoint, ApiMethod.GET)
      .subscribe((response: any) => {
        this.districtOptions = response;
        if (idString == '-1' && StateManagement.district) {
          this.districtAllOptions = response || [];
          this._common?.updateStoreData('district', 'district', response || []);
        }
      });
  }

  getDepartmentList() {
    let endpoint: any = Endpoints.GET_DROPDOWN_ENTITY;
    this._http
      .requestCall(endpoint, ApiMethod.GET)
      .subscribe((response: any) => {
        this.departmentOptions = response?.data || [];
        this.usersForm?.patchValue({
          user_type:
            this.departmentOptions.find(
              (o: any) =>
                o.id == this._lodash.getData(this.userApiData, 'user_type', '')
            ) || '',
        });
      });
  }

  getRoleList(idString: string = '-1') {
    let endpoint: any = Endpoints.GET_DROPDOWN_ROLES;
    if (idString != '-1') {
      endpoint += '?user_type=' + idString;
    }
    this._http
      .requestCall(endpoint, ApiMethod.GET)
      .subscribe((response: any) => {
        this.groupsOptions = response?.data || [];
        this.usersForm?.patchValue({
          groups:
            this.groupsOptions.find(
              (o: any) =>
                o.id == this._lodash.getData(this.userApiData, 'groups', '')
            ) || '',
        });
      });
  }

  onSelectRegion(selectedOptions: any = []) {
    if (!selectedOptions.length) {
      this.zoneOptions = [];
      this.districtOptions = [];
      this.usersForm.patchValue({ zone: [], district: [] });
    } else {
      //this.getMZO(this._lodash.mapData(selectedOptions, 'id').toString());
      this.usersForm.patchValue({
        region: selectedOptions,
        zone: [],
        district: [],
      });
    }
  }

  onSelectMZO(selectedOptions: any = []) {
    if (!selectedOptions.length) {
      this.districtOptions = [];
      this.usersForm.patchValue({ district: [] });
    } else {
      this.getDistrict(this._lodash.mapData(selectedOptions, 'id').toString());
      this.usersForm.patchValue({ zone: selectedOptions, district: [] });
    }
  }

  onSelectDistrict(selectedOptions: any) {
    this.usersForm.patchValue({ district: selectedOptions });
  }

  onSelectDepartment(selectedObj: any) {
    this.patchDropDownData('department', selectedObj, false);
    this.getRoleList(this._lodash.getData(selectedObj, 'id').toString());
  }

  onSelectRole(selectedObj: any) {
    this.patchDropDownData('role', selectedObj, false);
  }

  getRouteParams() {
    // this.routeEventSubscription = this.route.events.subscribe((event: any) => {
    //   if (this._lodash.getData(event, 'routerEvent.url', false)) {
    //     if (event.routerEvent.url.includes('/users/edit')) {
    //       this.type = 'edit';
    //     } else if (event.routerEvent.url.includes('/users/view')) {
    //       this.type = 'view';
    //     } else if (event.routerEvent.url.includes('/users/add')) {
    //       this.type = 'add';
    //     }
    //     if (event.routerEvent.url.includes('/users/edit') || event.routerEvent.url.includes('/users/view') || event.routerEvent.url.includes('/users/add')) {
    //       // this.setBreadCrumb();
    //       this.initialDependencies();
    //     }
    //     // }
    //   }
    // })
    this.type = this.majorDialogData.type;
    this.initialDependencies();
  }

  getUserId() {
    this.userId = this.majorDialogData?.id; //this.activated_route.snapshot.paramMap.get('id');
    this._userService.getUserList(this.userId);
  }

  addUserNameBreadCrumb() {
    if (this.type == 'view') {
      this.breadcrumbs.push({
        label: this._lodash.getData(this.userApiResponse, 'email', ''),
        url: '',
        textcase: true,
      });
      this.breadcrumbService.setCustomBreadCrumb(this.breadcrumbs);
    }
  }

  getUserDetails(data: any) {
    this.userApiResponse = data?.getUserDetails?.data;
    this.addUserNameBreadCrumb();
    this.convertAPIData(data?.getUserDetails?.data);

    this.setBreadCrumb();
    //Call Location API's................
    const locationDetails = this.transformData(
      this.userApiResponse?.locations?.country,
      'country'
    );
    const locationdetails = this.flattenJson(locationDetails, true);
    this.locationdetails = this._lodash.groupBy(locationdetails, 'key');
    if (this.type == 'edit') {
      this.dynamicLocation.setLocationDetails(this.userApiResponse?.locations);
      //this.dynamicLocation.locationItreation(this.locationdetails, 1);
    }

    //................Call Location API's
  }

  convertAPIData(response: any) {
    let data = this._lodash.Clonedata(response);
    data['regionIds'] =
      this._lodash.mapData(this._lodash.getData(data, 'region', []), 'id') ||
      [];
    data['zoneIds'] =
      this._lodash.mapData(this._lodash.getData(data, 'zone', []), 'id') || [];
    data['districtIds'] =
      this._lodash.mapData(this._lodash.getData(data, 'district', []), 'id') ||
      [];
    data['user_type'] = this._lodash.getData(data, 'user_type.id', '');
    data['region'] = this._lodash.getData(data, 'region', []);
    data['zone'] = this._lodash.getData(data, 'zone', []);
    data['district'] = this._lodash.getData(data, 'district', []);
    data['user_type'] = data['user_type'] || '';
    data['groups'] = this._lodash.getData(data, 'groups.0.id', '');
    data['profile_photo'] = this._lodash.getData(data, 'profile_photo', '');
    if (data.region.length) {
      //this.getMZO(data.regionIds.toString(), true);
    }
    if (data.zone.length) {
      this.getDistrict(data.zoneIds.toString(), true);
    }
    if (data.user_type) {
      this.getRoleList(data.user_type);
    }
    this.userApiData = data;

    this.patchUserData(data);
  }

  createForm() {
    this.enableEditForSameUser = this.userId === this.userDetails.id;
    this.usersForm = this.formBuilder.group({
      username: [
        { value: '', disabled: this.userId === this.userDetails.id },
        [
          this._formValidator.requiredValidationCheck('User Name is'),
          this._formValidator.minMaxLengthCheck(1, 100),
        ],
      ],
      first_name: [
        { value: '', disabled: this.userId === this.userDetails.id },
        [
          this._formValidator.requiredValidationCheck('First Name is'),
          this._formValidator.minMaxLengthCheck(1, 100),
        ],
      ],
      last_name: [
        { value: '', disabled: this.userId === this.userDetails.id },
        [
          this._formValidator.requiredValidationCheck('Last Name is'),
          this._formValidator.minMaxLengthCheck(1, 100),
        ],
      ], //this._formValidator.requiredValidationCheck('Given Name')
      email: [
        { value: '', disabled: this.userId === this.userDetails.id },
        [
          this._formValidator.requiredValidationCheck('Email is'),
          this._formValidator.emailValidationCheck,
        ],
      ],
      // region: [{ value: '', disabled: this.userId === this.userDetails.id }],
      // zone: [{ value: '', disabled: this.userId === this.userDetails.id }],
      // district: [{ value: '', disabled: this.userId === this.userDetails.id }],
      user_type: [
        { value: '', disabled: this.userId === this.userDetails.id },
        this._formValidator.requiredValidationCheck('User Group is'),
      ],
      groups: [
        { value: '', disabled: this.userId === this.userDetails.id },
        this._formValidator.requiredValidationCheck('Role is'),
      ],
      status: [{ value: true, disabled: this.userId === this.userDetails.id }],
      date_of_birth: [
        '',
        [this._formValidator.requiredValidationCheck('Date of Birth is')],
      ],
      profile_photo: [''],
      local_photo: [''],
      zone_valuer: [''],
      phone_number: [
        '',
        [
          this._formValidator.requiredValidationCheck('Phone Number is'),
          this._formValidator.mobilePatternValidationCheck(
            this.phoneNumberMaxLength
          ),
          this._formValidator.firstDigitZeroValidationCheck,
        ],
      ],
      country_code: ['+250'],
      location: this.formBuilder.group({}),
      // view_all_entity: [{value: '', disabled: this.userId === this.userDetails.id}]
    });
    this.location = this.formBuilder.group({});
    this.cdr.detectChanges();
    
  }

  patchDropDownData(
    fieldName: string,
    data: any,
    storeAsArray: boolean = true,
    canSelectMultipleOptions: boolean = true
  ) {
    let id = this._lodash.getData(data, 'id', 0);
    let name = this._lodash.getData(data, 'name', 0);

    if (!storeAsArray) {
      this.usersForm.patchValue({ fieldName: name });
    } else {
      let currentVal = this.usersForm.get(fieldName).value || [];
      if (!canSelectMultipleOptions) {
        currentVal = [id];
      } else {
        currentVal = this._lodash.removeFromArray(
          currentVal,
          this._lodash.getData(data, 'id', 0)
        );
        currentVal.push(id);
      }

      this.usersForm.patchValue({ fieldName: currentVal });
    }
  }

  patchUserData(data: any) {
    if (this.type == 'edit' || this.type == 'add') {
      let patchData = {
        region: data.region,
        zone: data.zone,
        profile_photo: data.profile_photo || '',
        district: data.district,
        user_type:
          this.departmentOptions.find((o: any) => o.id == data.user_type) || '',
        groups: this.groupsOptions.find((o: any) => o.id == data.groups) || '',
        email: data.email,
        username: data.username,
        last_name: data.last_name,
        first_name: data.first_name,
        date_of_birth: data.date_of_birth,
        zone_valuer: data.zone_valuer,
        phone_number: data.phone_number,
        country_code: data.country_code,
        status: data.is_active,
        view_all_entity: data.view_all_entity,
      };
      this.usersForm.patchValue(patchData);
    } else {
      this.viewUser = {
        username: data.username || '',
        first_name: data.first_name || '',
        last_name: data.last_name || '',
        email: data.email || '',
        date_of_birth:
          this.datePipe?.transform(data.date_of_birth, FORMAT.DATE_FORMAT) ??
          '',
        phone_number: data.phone_number || '',
        country_code: data.country_code || '',
        status: data?.is_active || '',
        region: (
          this._lodash.mapData(
            this._lodash.getData(this.userApiResponse, 'region', []),
            'display_name'
          ) || []
        ).toString(),
        zone: (
          this._lodash.mapData(
            this._lodash.getData(this.userApiResponse, 'zone', []),
            'display_name'
          ) || []
        ).toString(),
        district: (
          this._lodash.mapData(
            this._lodash.getData(this.userApiResponse, 'district', []),
            'display_name'
          ) || []
        ).toString(),
        user_type: this._lodash.getData(
          this.userApiResponse,
          'user_type.display_name',
          ''
        ),
        groups: this._lodash.getData(
          this.userApiResponse,
          'groups.0.display_name',
          ''
        ),
        profile_photo: this._lodash.getData(
          this.userApiResponse,
          'profile_photo',
          ''
        ),
        zone_valuer: '',
        view_all_entity: data.view_all_entity || '',
      };
    }
  }

  getSelectedRoles(role: any): any {
    return this.rolesList.find((findRoles: any) => findRoles?.id === role?.id);
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.usersForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  getFinalFormData() {
    // get location details ...................
    const selectedLocation = this.dynamicLocation.getLeastRec();
    const rawValue =
      this.dynamicLocation?.getLocationForm
        ?.get(selectedLocation?.controlName)
        ?.getRawValue() || [];
    const locationIds = rawValue?.map((x: any) => x.id);

    // ...................get location details
    let data = this.usersForm?.getRawValue();
    data.is_active = data.status;
    let finalData: any;
    if (this.type == 'edit') {
      data['date_of_birth'] = this.datePipe?.transform(
        this.usersForm.value.date_of_birth,
        'yyyy-MM-dd',
        'en-US'
      );
      data['groups'] = [data['groups']['id']];
      data['user_type'] = data['user_type']['id'];
      data['locations'] = locationIds;

      delete data.mzo;
      delete data.location;
      delete data.local_photo;
      delete data.profile_photo;
      delete data.zone_valuer;
      delete this.userApiData.profile_photo;
      delete this.userApiData.regionIds;
      delete this.userApiData.districtIds;
      delete this.userApiData.zoneIds;

      if (this.userApiData.hasOwnProperty('created_by'))
        delete this.userApiData.created_by;

      if (this.userApiData.hasOwnProperty('last_updated_by'))
        delete this.userApiData.last_updated_by;

      finalData = { ...this.userApiData, ...data };
    } else {
      data['date_of_birth'] = this.datePipe?.transform(
        this.usersForm.value.date_of_birth,
        'yyyy-MM-dd',
        'en-US'
      );
      data['groups'] = [data['groups']['id']];
      data['user_type'] = data['user_type']['id'];
      data['locations'] = locationIds;

      delete data.mzo;
      delete data.location;
      delete data.local_photo;
      delete data.profile_photo;
      delete data.zone_valuer;

      finalData = data;
    }

    return finalData;
  }

  submitUsers() {
    if (this.usersForm.invalid) {
      this.findInvalidControls();
      this.usersForm.markAllAsTouched();
      return;
    } else {
      this.initiateSaveAPI();
    }
  }
  drawTemplate(response: any) {
    if (this.type == 'edit') {
      return null;
    } else {
      return ` <div class="row bg_user_add_new mx-auto">
      
     
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div>
                    <span>${this.staticText?.user_management?.user?.view_edit?.template_field1}</span>
                    <p class= "user_name_display">${response?.body?.data?.first_name}
                        ${response?.body?.data?.last_name}</p>
                    </div>

                    </div>

                     <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div>
                    <span>${this.staticText?.user_management?.user?.view_edit?.template_field2}</span>
                    <p class= "user_name_display">${response.body.data.user_type.display_name}</p>
                    </div>

                    </div>
                     <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div>
                    <span>${this.staticText?.user_management?.user?.view_edit?.template_field3}</span>
                    <p class= "user_name_display">${response.body.data.groups[0].display_name}</p>
                    </div>

                    </div>

                    
                    
                    
                    </div>`;
    }
  }

  initiateSaveAPI() {
    this._loader.show();
    let endpoint: any =
      this.type === 'add'
        ? Endpoints.GET_USER_LISTS
        : Endpoints.GET_USER_LISTS + this.userId + '/';
    const apiMethod = this.type === 'add' ? ApiMethod.POST : ApiMethod.PUT;
    const payload = this.getFinalFormData();
    let formData: any = new FormData();
    formData.append('json_data', JSON.stringify(payload));
    if (this.selectedAttachedFile) {
      formData.append('profile_photo', this.selectedAttachedFile);
    }
    this._http
      .requestCall(endpoint, apiMethod, formData, {
        observe: 'response',
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .subscribe(
        (response: any) => {
          this._loader.hide();
          this._createDialogRef.close(true);
          this._modal.openSucceedDialog({
            data: {
              header:
                this.type == 'add'
                  ? `<div>${this.staticText?.user_management?.user?.view_edit?.success_add_header}</div>`
                  : `<div>${this.staticText?.user_management?.user?.view_edit?.success_edit_header}</div>`,
              msg:
                this.type == 'add'
                  ? ` <p class=" success-text"  [innerHTML]="successDialogData?.msg">
                     ${this.staticText?.user_management?.user?.view_edit?.sucess_add_msg}
                    </p>`
                  : `<p class=" success-text"  [innerHTML]="successDialogData?.msg">
                         <span class="user_name">${response?.body?.data?.first_name}
                        ${response?.body?.data?.last_name}</span> ${this.staticText?.user_management?.user?.view_edit?.sucess_edit_msg}
                    </p>`,
              template: this.drawTemplate(response),

              paragraph:
                this.type === 'add'
                  ? SuccessMessage.USER_CREATE_MSG
                  : SuccessMessage.USER_EDIT_MSG,
            },
          });
          this.router.navigate(['iam/user-management/users/']);
        },
        (err: any) => {
          this._loader.hide();
        }
      );
  }

  editUser() {
    this.router.navigate(['iam/user-management/users/edit/' + this.userId]);
  }

  close() {
    // this.router.navigate(['/user-management/users']);
    this._createDialogRef.close(false);
  }

  approveUsers(approve: boolean = true) {
    this._modal
      .openWarningDialog({
        data: {
          paragraph: `<p class="fs-14">${approve
            ? 'Are You Sure?.You want to approve this Valuer'
            : 'Are You Sure?.You want to reject this Valuer'
            }</p>`,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this._loader.show();
          let endpoint: any = Endpoints.GET_USER_LISTS + this.userId + '/';
          const apiMethod = ApiMethod.PATCH;
          const payload = {
            is_active: approve ? true : false,
            is_deleted: approve ? false : true,
          };
          this._http
            .requestCall(endpoint, apiMethod, payload, {
              observe: 'response',
              headers: { 'Content-Type': 'multipart/form-data' },
            })
            .subscribe(
              (response: any) => {
                this._loader.hide();
                if (approve) {
                  this._modal.openSuccessDialog({
                    data: {
                      paragraph: SuccessMessage.PRIVATE_VALUER_APPROVE_MSG,
                    },
                  });
                }
                if (!approve) {
                  this._snackBar.loadSnackBar(
                    this._lodash.getData(
                      response,
                      'body.details',
                      SuccessMessage.PRIVATE_VALUER_REJECT
                    ),
                    colorCodes.SUCCESS
                  );
                }

                this.router.navigate(['/users/list/1']);
              },
              (err: any) => {
                this._loader.hide();
              }
            );
        } else {
          this.router.navigate(['/users/list/1']);
        }
      });
  }

  /**
   * @description
   *  When the location changed..............
   * @param ev current data
   */
  onSelectLocation(ev: any, data: any) {
    this.locationdetails = {};

    if (ev?.length) {
      this._userService.locationJson?.forEach((element: any) => {
        element.gotSelect = false;
      });
      data.gotSelect = true;
    }
    let hasRemove = false;
    let removeIndex = 0;
    let removeLength = 0;
    this._userService.locationJson?.forEach((element: any, index: any) => {
      if (data?.index < element?.index || hasRemove) {
        if (!hasRemove) {
          if (this._userService.locationJson[index - 1])
            this._userService.locationJson[index - 1].gotSelect = true;
          removeIndex = index;
        }
        hasRemove = true;
        this.getLocationForm.removeControl(element?.controlName);
        removeLength += 1;
      }
    });
    this._userService.locationJson?.splice(removeIndex, removeLength);
    const locationId = ev?.map?.((x: any) => x.id)?.toString();

    const listOfIndex = this._userService.locationJson?.map(
      (x: any) => x.index
    );
    let maxValue = 0;
    if (!listOfIndex?.length) maxValue = listOfIndex?.length;
    else maxValue = Math.max(...listOfIndex);
    this._loader.show();
    this.getLocation(locationId, maxValue + 1);
  }

  //..............When the location changed

  /**
   * @description
   * GET location details from API here...................
   * @param parent
   */

  getLocation(parent?: any, order?: number) {
    this._userService.getLocation(parent, order).subscribe((response: any) => {
      this._userService.locationJson?.forEach((element: any) => {
        this._loader.hide();
        let patchdata = '';
        if (this.type == 'edit') {
          if (this.locationdetails?.[element?.controlName]) {
            const ids = this.locationdetails?.[element?.controlName]?.map(
              (x: any) => x.id
            );
            patchdata = element?.data?.filter((x: any) => ids.includes(x.id));
          }
        }

        this.getLocationForm.addControl(
          element?.controlName,
          new FormControl('', [
            this._formValidator.requiredValidationCheck(element?.name),
          ])
        );
        if (this.type == 'edit' && patchdata) {
          this.getLocationForm.get(element?.controlName)?.setValue(patchdata);
        }
      });
    });
  }

  //...................GET location details from API here

  get getLocationForm(): FormGroup {
    return this.location;
  }

  /**
   * @description
   * Filter array keyname id's............................
   * @param data
   * @returns
   */
  transformData(data: any, keyName: string) {
    return data?.map((item: any) => {
      let transformed: any = {
        id: item.id,
        key: keyName,
        display_name: item.display_name,
      };

      if (item.child_keys.length > 0) {
        transformed.child = [];
        item.child_keys.forEach((key: string) => {
          transformed.child = transformed.child.concat(
            this.transformData(item[key], key)
          );
        });
      }

      return transformed;
    });
  }

  //............................Filter array keyname id's

  /**
   * @description
   * Location details load depand on user details............
   * @param data Location details
   * @param index current indec
   */

  locationItreation(data: any, index: number) {
    Object.keys(data)?.forEach((element: any, index: any) => {
      const ids = data[element]?.map((x: any) => x.id)?.toString();
      this.getLocation(ids, index);
    });
  }

  //............Location details load depand on user details

  get viewLocationDetails(): any {
    let groupDetails = [];
    for (
      let index = 0;
      index < Object.keys(this.locationdetails).length;
      index++
    ) {
      const element = Object.keys(this.locationdetails)[index];
      let obj: any = {};
      obj.display_name = element;
      obj.group = this.locationdetails[element];
      obj.concatName = this.locationdetails[element]
        ?.map((x: any) => x.display_name)
        ?.toString();
      groupDetails.push(obj);
    }
    return groupDetails;
  }

  /**
   * @description
   * Nested JSON built as flattern JSON................................
   * @param jsonData
   * @param deleteMode
   * @returns
   */

  flattenJson(jsonData: any, deleteMode: boolean) {
    let result: any = [];

    function flatten(item: any) {
      result.push(item);
      if (item.child) {
        item.child.forEach(flatten);
        if (deleteMode) {
          delete item.child;
        }
      }
    }
    jsonData?.forEach(flatten);
    return result;
  }

  setMinDate() {
    const currentYear = this.currentDate.getFullYear();
    this.minDate = new Date(
      currentYear - 118,
      this.currentDate.getMonth(),
      this.currentDate.getDate()
    );
    this.maxDate = new Date(
      currentYear - 18,
      this.currentDate.getMonth(),
      this.currentDate.getDate()
    );
  }

  //................................Nested JSON built as flattern JSON

  ngOnDestroy() {
    this.routeEventSubscription?.unsubscribe();
    this.regionListSub$?.unsubscribe();
    this.mzoListSub$?.unsubscribe();
    this.districtListSub$?.unsubscribe();
  }

  updateStatus(status: boolean) {
    this.usersForm.get('status').setValue(status);
  }
}
